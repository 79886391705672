import {graphql} from 'gatsby';
import React from 'react';
import BoxGridWrapper from '../components/Wrapper/BoxGridWrapper';
import PageButtons from '../components/Pagination/PageButtons';
import PostCard from '../components/PostCard/PostCard';
import Seo from '../components/Seo/Seo';

const AuthorTemplate = ({
  data: {author, postsCurrentPage, postsOfAuthor},
  pageContext: {numPages, currentPage},
}) => {
  return (
    <div className="pt-[70px] lg:pt-20">
      {/* Author Header */}
      <div className="mx-auto my-5 flex max-w-[960px] flex-col items-center justify-center lg:my-10">
        <img
          src={author.avatar.url}
          alt={author.name}
          className="rounded-full"
          width={60}
          height={60}
        />
        <h1 className="mt-3 text-3xl font-bold lg:mt-5">{author.name}</h1>
        {author.description && <p>{author.description}</p>}

        <div className="mt-3 lg:mt-5">
          {author.registeredDate && (
            <div>Registered on: {author.registeredDate}</div>
          )}
          {postsOfAuthor.nodes?.length > 0 && (
            <div className="capitalize">
              Số bài viết: {postsOfAuthor.nodes.length}
            </div>
          )}
        </div>
      </div>

      {/* Posts List Start */}
      <BoxGridWrapper className="gap-4">
        {postsCurrentPage.nodes.map((post) => (
          <PostCard key={post.id} post={post} />
        ))}
      </BoxGridWrapper>

      {numPages > 1 && (
        <PageButtons
          prefixPathName={author.uri}
          currentPage={currentPage}
          numPages={numPages}
        />
      )}
    </div>
  );
};

export default AuthorTemplate;

export const Head = ({data: {author}}) => {
  return (
    <Seo
      yoastSeo={author.seo}
      title={author.name}
      uri={author.uri}
      description={author.description && author.description.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query ($authorId: String!, $skip: Int!, $limit: Int!) {
    author: wpUser(id: {eq: $authorId}) {
      id
      avatar {
        url
      }
      registeredDate
      name
      uri
      seo {
        fullHead
        canonical
      }
    }
    postsOfAuthor: allWpPost(filter: {author: {node: {id: {eq: $authorId}}}}) {
      nodes {
        id
      }
    }
    postsCurrentPage: allWpPost(
      filter: {author: {node: {id: {eq: $authorId}}}}
      sort: {order: DESC, fields: date}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        uri
        slug
        date(formatString: "DD.MM.YYYY")
        categories {
          nodes {
            id
            name
            uri
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 200)
              }
            }
          }
        }
      }
    }
  }
`;
